import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';

export const Recently: FC<IconProps> = ({ fill }) => {
	const _fill = fill || 'white';
	return (
		<Svg
			content={
				<>
					<path
						d="M7.99992 14.6663C11.6818 14.6663 14.6666 11.6816 14.6666 7.99967C14.6666 4.31778 11.6818 1.33301 7.99992 1.33301C4.31802 1.33301 1.33325 4.31778 1.33325 7.99967C1.33325 11.6816 4.31802 14.6663 7.99992 14.6663Z"
						stroke={_fill}
						fill="transparent"
						strokeWidth="1.5"
						strokeMiterlimit="10"
					/>
					<path
						d="M8 8H11.5"
						stroke={_fill}
						fill="transparent"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10.475 10.475L8 8"
						stroke={_fill}
						fill="transparent"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</>
			}
			width={16}
			height={16}
		/>
	);
};

import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Trans, trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { LinkTo } from '../../../default/LinkTo/LinkTo';
import routingService from '../../../../../LegacyApp/client/modules/app/routingService';
import { BigPromoStyled } from './Promotions.styled';

export const VipClubOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	return (
		<LinkTo
			className={'onclick-wrapper onclick-wrapper-vipclub'}
			href={routingService.to(ROUTING_ID.USER_VIP)}
			forceATag
		>
			{children}
		</LinkTo>
	);
};

export const VipClubPromo: FC = () => {
	return (
		<VipClubPromoStyled>
			<VipClubOnClickWrapper />
			<DiamondsImage />
			<h4 className="text-style-h-4">
				{trans({
					label: 'vip <1/>club',
					namespace: TransNamespace.COMMON,
					components: (
						<>
							vip
							<br />
							club
						</>
					),
				})}
			</h4>
			<h6 className="text-style-h-7">
				<Trans label={'Exclusive VIP Experience'} />
			</h6>
		</VipClubPromoStyled>
	);
};

export const DiamondsImage = styled(Image).attrs({
	src: '/img/promosection/vipclub/diamonds.png',
	alt: 'Coins',
})`
	top: 0;
	position: absolute;
	height: 100%;
	right: -110px;
	z-index: 2;

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${calcStyle({ a: '1060px', b: viewportPadding })}) {
				right: -90px;
			}
		`,
	)}
`;

export const VipClubPromoStyled = styled(BigPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/vipclub/bg.svg')});
	background-repeat: no-repeat;
	background-size: cover;

	h4,
	h6 {
		z-index: 3;
	}
`;

import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Trans } from '@legacyApp/client/modules/translation/translate';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { LinkTo } from '../../../default/LinkTo/LinkTo';
import routingService from '../../../../../LegacyApp/client/modules/app/routingService';
import { Image, parseImageSrc } from '../../../default/Image/Image';
import { MediumPromoStyled } from './Promotions.styled';

export const ChatRainOnClickWrapper: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	return (
		<LinkTo
			className={'onclick-wrapper onclick-wrapper-chatrain'}
			href={routingService.to(ROUTING_ID.PROMOTIONS)}
			forceATag
		>
			{children}
		</LinkTo>
	);
};

export const ChatRainPromo: FC = () => {
	return (
		<ChatRainPromoStyled>
			<ChatRainOnClickWrapper />
			<PopupImage />
			<h5 className="text-style-h-6">
				<Trans
					label={'chat rain &<1/> giveaways'}
					components={
						<>
							chat rain &<br />
							giveaways
						</>
					}
				/>
			</h5>
		</ChatRainPromoStyled>
	);
};

export const PopupImage = styled(Image).attrs({
	src: '/img/promosection/chatrain/popup.png',
	alt: 'Chat message pop ups',
})`
	top: 22%;
	position: absolute;
	height: 99%;
	right: 1px;
	transition: 300ms ease-out;
	z-index: 1;
`;

export const ChatRainPromoStyled = styled(MediumPromoStyled)`
	background-image: url(${parseImageSrc('/img/promosection/chatrain/bg.svg')});
	background-repeat: no-repeat;
	background-size: cover;

	h4,
	h6 {
		z-index: 3;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			${PopupImage} {
				transform: translate(0, -13px);
			}
		}
	}
`;

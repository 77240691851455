import { FC } from 'react';
import styled, { css } from 'styled-components';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { Title } from './components/Title';
import { Promotions } from './components/Promotions';

export const WelcomeSectionLoggedOut: FC = () => {
	return (
		<WelcomeSectionLoggedOutStyled>
			<Title />
			<Promotions />
		</WelcomeSectionLoggedOutStyled>
	);
};

export const WelcomeSectionLoggedOutStyled = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 16px;
	justify-content: space-between;
	margin-bottom: 80px;
	margin-top: 60px;

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media.maxWidthTablet,
						b: viewportPadding,
					})}) {
				flex-direction: column;
				align-items: flex-start;
				gap: 64px;
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				gap: 32px;
				margin-bottom: 40px;
				margin-top: 30px;
			}
		`,
	)};
`;

import { useCallback } from 'react';
import { Slot } from '@modules/slots/store/slots.types';
import { useFetchGames } from '@modules/slots/hooks/useFetchGames';
import { SLOTS_SCROLL_CARD_COUNT } from '@modules/slots/constants/Slots.constants';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { GameSliderId } from '../components/GamesSliderBox';

interface UseGameBox {
	id: GameSliderId;
}

export const useGameBox = ({ id }: UseGameBox) => {
	const { callback } = useFetchGames();
	const gamesCallback = useCallback(
		() =>
			callback({
				count: SLOTS_SCROLL_CARD_COUNT,
				filterType: id,
			}),
		[callback, id],
	);
	const { data: list, loaded } = useGetDataArray<Slot>(
		String(id),
		gamesCallback,
	);

	return {
		list,
		loaded,
	};
};

import React from 'react';
import { MainLayout } from '@legacyApp/client/components/mainLayout';
import { StyledHomeGlobal } from './Home.global.styled';
import { SectionsTabNav } from './SectionsTabNav/SectionsTabNav';
import { WelcomeSection } from './WelcomSection/WelcomeSection';

export const HomeContent = () => {
	return (
		<>
			<StyledHomeGlobal />
			<MainLayout v2>
				<WelcomeSection />
				<SectionsTabNav defaultTabId="lobby" />
			</MainLayout>
		</>
	);
};

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Button, CSSClassNameButton } from '@ui/button';

import { trans } from '@legacyApp/client/modules/translation/translate';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { Size } from '@uiTypes/Size';

import { Redirect } from '@common/components/default/Redirect/Redirect';

export const TitleStyled = styled.section`
	max-width: 320px;

	h1 {
		margin-top: 0;
		margin-bottom: 0;
	}

	p {
		color: var(--color-dark-50);
		margin-top: 16px;
	}

	.${CSSClassNameButton} {
		border-radius: 5px;
		margin-top: 40px;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media.maxWidthTablet,
						b: viewportPadding,
					})}) {
				max-width: unset;
				width: 100%;
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				p {
					margin-top: 8px;
				}

				.${CSSClassNameButton} {
					margin-top: 20px;
				}
			}
		`,
	)};
`;

export const Title: FC = () => {
	return (
		<TitleStyled>
			<h1 className="text-style-h-1">
				{trans({
					label: 'Crypto casino Wolfbet',
					namespace: TransNamespace.HOME_PAGE,
				})}
			</h1>

			<p className="text-style-md-medium">
				{trans({
					label:
						'Enjoy 5000+ crypto games, fast payouts and 24/7 support. Make the best of superior Bitcoin Casino',
					namespace: TransNamespace.HOME_PAGE,
				})}
				.
			</p>

			<Redirect href={'/register'} rel="nofollow">
				<Button
					label={trans({
						label: 'Join Wolf Pack',
						namespace: TransNamespace.HOME_PAGE,
					})}
					size={Size.LARGE}
				/>
			</Redirect>
		</TitleStyled>
	);
};

import { FC } from 'react';

import { useUserIsLogged } from '@common/selectors/user.selectors';

import { WelcomeSectionLoggedIn } from '@common/components/home/WelcomSection/WelcomeSectionLoggedIn';
import { WelcomeSectionLoggedOut } from '@common/components/home/WelcomSection/WelcomeSectionLoggedOut';

export const WelcomeSection: FC = () => {
	const isLogged = useUserIsLogged();

	if (isLogged) {
		return <WelcomeSectionLoggedIn />;
	}

	return <WelcomeSectionLoggedOut />;
};

import { FC } from 'react';
import styled, { css } from 'styled-components';

import { useGameBox } from '@common/components/home/WelcomSection/hooks/useGameBox';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';

import { BiggestWins } from '@icons/biggest-wins';
import { Recently } from '@icons/recently';
import { Trending } from '@icons/trending';
import {
	GameSliderBoxStyled,
	GameSliderId,
	GamesSliderBox,
} from '@common/components/home/WelcomSection/components/GamesSliderBox';

export const WelcomeSectionLoggedIn: FC = () => {
	const biggestWinsData = useGameBox({ id: GameSliderId.BIGGEST_WINS });
	const trendingData = useGameBox({ id: GameSliderId.TRENDING });
	const recentlyPlayedData = useGameBox({ id: GameSliderId.RECENTLY_PLAYED });

	if (
		!biggestWinsData.list?.length &&
		!trendingData.list?.length &&
		!recentlyPlayedData.list?.length
	) {
		return;
	}

	return (
		<WelcomeSectionLoggedInStyled>
			<GamesSliderBox
				list={biggestWinsData.list}
				loaded={biggestWinsData.loaded}
				title={
					<BiggestStyled>
						<IconBackgroundStyled>
							<BiggestWins />
						</IconBackgroundStyled>

						<span>
							{trans({
								label: '<0>Biggest</0> Wins',
								namespace: TransNamespace.HOME_PAGE,
								components: (
									<>
										<span className="game-slider-box-color">Biggest</span> Wins
									</>
								),
							})}
						</span>
					</BiggestStyled>
				}
			/>

			<GamesSliderBox
				list={trendingData.list}
				loaded={trendingData.loaded}
				title={
					<TrendingStyled>
						<IconBackgroundStyled>
							<Trending />
						</IconBackgroundStyled>

						<span>
							{trans({
								label: '<0>Trending</0> Right Now',
								namespace: TransNamespace.HOME_PAGE,
								components: (
									<>
										<span className="game-slider-box-color">Trending</span>{' '}
										Right Now
									</>
								),
							})}
						</span>
					</TrendingStyled>
				}
			/>

			<GamesSliderBox
				list={recentlyPlayedData.list}
				loaded={recentlyPlayedData.loaded}
				title={
					<RecentlyPlayedStyled>
						<IconBackgroundStyled>
							<Recently />
						</IconBackgroundStyled>

						<span>
							{trans({
								label: '<0>Recently</0> Played',
								namespace: TransNamespace.HOME_PAGE,
								components: (
									<>
										<span className="game-slider-box-color">Recently</span>{' '}
										Played
									</>
								),
							})}
						</span>
					</RecentlyPlayedStyled>
				}
			/>
		</WelcomeSectionLoggedInStyled>
	);
};

export const WelcomeSectionLoggedInStyled = styled.section`
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(3, calc((100% / 3) - (10px / 3)));
	margin-bottom: 80px;
	margin-top: 60px;

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_large,
						b: viewportPadding,
					})}) {
				grid-template-columns: repeat(2, calc((100% / 2) - (10px / 2)));

				${GameSliderBoxStyled}:nth-of-type(3) {
					display: none;
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_large,
						b: viewportPadding,
					})}) {
				grid-template-columns: 100%;

				${GameSliderBoxStyled}:nth-of-type(2) {
					display: none;
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				margin-bottom: 40px;
				margin-top: 30px;
			}
		`,
	)};
`;

export const IconBackgroundStyled = styled.div`
	align-items: center;
	border-radius: 100%;
	display: flex;
	height: 28px;
	justify-content: center;
	width: 28px;
`;

export const TitleStyled = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	justify-content: flex-start;
`;

export const BiggestStyled = styled(TitleStyled)`
	.game-slider-box-color {
		color: var(--color-other-success);
	}

	${IconBackgroundStyled} {
		background-color: var(--color-other-success);
	}
`;

export const TrendingStyled = styled(TitleStyled)`
	.game-slider-box-color {
		color: var(--color-blue-brand-blue);
	}

	${IconBackgroundStyled} {
		background-color: var(--color-blue-brand-blue);
	}
`;

export const RecentlyPlayedStyled = styled(TitleStyled)`
	.game-slider-box-color {
		color: var(--color-other-pink);
	}

	${IconBackgroundStyled} {
		background-color: var(--color-other-pink);
	}
`;

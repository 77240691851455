import { useState } from 'react';
import { HomeContent } from '@common/components/home/HomeContent';
import { SlotsBottomSectionsContext } from '@modules/slots/context/SlotsBottomSectionsContext';
import { SlotsCategoriesContext } from '@modules/slots/context/SlotsCategoriesContext';
import { SlotsFirstSectionContext } from '@modules/slots/context/SlotsFirstSectionContext';
import { SlotsProvidersContext } from '@modules/slots/context/SlotsProvidersContext';

export const HomeLayout = (context) => {
	const { params } = context;

	const { firstSection, bottomSections, providers, categories } = params;

	const [providers_, setProviders_] = useState(providers);

	const [categories_, setCategories_] = useState(categories);

	const [firstSection_, setFirstSection_] = useState(firstSection);

	const [bottomSections_, setBottomSections_] = useState(bottomSections);

	return (
		<SlotsBottomSectionsContext.Provider
			value={{
				bottomSections: bottomSections_,
				setBottomSections: setBottomSections_,
			}}
		>
			<SlotsFirstSectionContext.Provider
				value={{
					firstSection: firstSection_,
					setFirstSection: setFirstSection_,
				}}
			>
				<SlotsProvidersContext.Provider
					value={{ providers: providers_, setProviders: setProviders_ }}
				>
					<SlotsCategoriesContext.Provider
						value={{ categories: categories_, setCategories: setCategories_ }}
					>
						<HomeContent />
					</SlotsCategoriesContext.Provider>
				</SlotsProvidersContext.Provider>
			</SlotsFirstSectionContext.Provider>
		</SlotsBottomSectionsContext.Provider>
	);
};

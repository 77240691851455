import { FC } from 'react';
import styled, { css } from 'styled-components';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { VipWheelPromo } from './VipWheelPromo';
import { RakebackPromo, RakebackPromoStyled } from './RakebackPromo';
import { ChatRainPromo, ChatRainPromoStyled } from './ChatRainPromo';
import { BonusCodesPromo } from './BonusCodesPromo';
import { WolfRacePromo } from './WolfRacePromo';
import { BigPromoStyled, MediumPromoStyled } from './Promotions.styled';
import { VipClubPromo } from './VipClubPromo';

export const Promotions: FC = () => {
	return (
		<PromotionsStyled>
			<VipClubPromo />
			<VipWheelPromo />
			<RakebackPromo />
			<BonusCodesPromo />
			<ChatRainPromo />
			<WolfRacePromo />
		</PromotionsStyled>
	);
};

export const PromotionsStyled = styled.section`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: flex-end;
	max-width: 896px;
	min-width: 896px;

	.onclick-wrapper {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 99;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_desktop_small,
						b: viewportPadding,
					})}) {
				max-width: 668px;
				min-width: 668px;

				${ChatRainPromoStyled},
				${RakebackPromoStyled} {
					display: none;
				}
			}

			@media (max-width: ${calcStyle({ a: '1060px', b: viewportPadding })}) {
				max-width: 578px;
				min-width: 578px;

				${BigPromoStyled} {
					width: 350px;
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_large,
						b: viewportPadding,
					})}) {
				max-width: 534px;
				min-width: unset;

				${BigPromoStyled},
				${MediumPromoStyled} {
					height: 168px;
				}

				${MediumPromoStyled} {
					width: 168px;
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media.maxWidthTablet,
						b: viewportPadding,
					})}) {
				max-width: unset;
				justify-content: flex-start;

				${BigPromoStyled} {
					width: calc(100% - 16px - 168px);
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_large,
						b: viewportPadding,
					})}) {
				${BigPromoStyled} {
					width: 100%;
				}

				${MediumPromoStyled} {
					width: calc(50% - 8px);
				}
			}

			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				gap: 8px;

				${BigPromoStyled},
				${MediumPromoStyled} {
					height: 140px;
				}

				${MediumPromoStyled} {
					width: calc(50% - 4px);
				}
			}
		`,
	)};
`;
